<template>
  <breadcrumb-page :list="breadcrumb_links"></breadcrumb-page>

  <div id="maincontent_container">
    <company-submenu></company-submenu>
    <div class="submenu-content">
      <p class="lead">Terms and Conditions</p>
      <p>
        <img src="@/assets/images/pdf.png" :height="50" :width="50" />
        <a :href="'/' + item" class="blue_link" target="_blank"
          >Download Terms and Conditions</a
        >
      </p>
      <br />
      <p class="lead">General Terms &amp; Conditions of Sale of Goods</p>
      <p>
        The within General Terms &amp; Conditions of Sale (“Terms &amp;
        Conditions of Sale”) govern and apply to any and all sales of IT
        Products (or services) made by Ma Laboratories, Inc. or its any of its
        affiliates as defined below (hereafter “Ma Labs”) to any customer or
        account of Ma Labs (hereafter “BUYER”) whether wholesale or for its own
        account, or got the account of, or resale directly or indirectly to or
        for the benefit of any customer or end-user of BUYER (or BUYER’S
        customer). These Terms &amp; Conditions of Sale, see
        <router-link :to="{ name: 'TermsAndConditions' }"
          >https://www.malabs.com/terms-conditions</router-link
        >, incorporate and include, by this reference, the Supplemental Terms
        &amp; Conditions of Sale Re Federal Trade Restrictions that more
        specifically govern such purchase-sale transactions. See
        <router-link :to="{ name: 'TermsAndConditionsSuppl' }">
			https://www.malabs.com/terms-conditions-suppl</router-link>.
      </p>
      <br />
      <p>
        BUYER expressly agrees that these Terms and Conditions of Sale govern
        its purchase of any IT Products (hereafter “IT Products” or “Products”)
        from Ma Labs, and the provision of any and all other materials, goods
        and services from and by Ma Labs, and any of its divisions,
        subsidiaries, and affiliated corporations, business entities, or
        persons, as well as by any third-party vendors and/or service providers
        of Ma Labs. BUYER further agrees that these terms and conditions,
        whether printed on a purchase order or otherwise, supersede and take
        precedence over BUYER’S supplemental or conflicting terms and conditions
        to which notice of objection is hereby given. Any conflicting statements
        or terms on BUYER’S purchase orders, invoices, confirmations or other
        documents generated by BUYER (or BUYER’S customer or end-user)are
        negated by this Agreement.
      </p>
      <br />
      <p>
        <b
          >THESE TERMS &amp; CONDITIONS OF SALE (INCLUDING THE SUPPLEMENTAL
          TERMS &amp; CODITIONS OF SALE RE FEDERAL TRADE RESTRICTIONS) ARE
          CONTROLLING AND MAY ONLY BE WAIVED OR MODIFIED IN A WRITTEN AGREEMENT
          SIGNED BY A DULY AUTHORIZED OFFICER OF MA LABS.</b
        >
      </p>
      <br />
      <p>
        Ma Labs’s performance of any obligation to BUYER is expressly made
        conditional on BUYER’S acceptance and agreement to Ma Labs’s Terms and
        Conditions of Sale herein, unless otherwise agreed to in writing by Ma
        Labs. These Terms &amp; Conditions of Sale may not be excused, modified
        or altered in any way by any statement, oral or written, of any Ma Labs
        account representative, without the knowledge of and an express written
        agreement signed by a duly authorized officer of Ma Labs. In the absence
        of such agreement, Ma Labs’s commencement of performance and/or delivery
        of Product shall be for BUYER’S convenience only and shall not be deemed
        or construed to be acceptance of BUYER’S terms and conditions or any of
        them. BUYER’S acceptance of any Product or Products shall be deemed
        acceptance of the terms and conditions stated herein. Acceptance by
        BUYER is limited to and conditioned upon BUYER’S assent to these terms
        and conditions. Neither Ma Labs’s commencement of performance or
        delivery shall be deemed or constituted as acceptance of BUYER’S
        supplemental or conflicting terms and conditions. BUYER’S acceptance of
        the Products and/or Services from Ma Labs shall be deemed to constitute
        acceptance of the terms and conditions contained herein.
      </p>
      <br />
      <p class="lead">Quotations and Pricing</p>
      <p>
        Unless otherwise indicated, all price quotes are based on a cash
        discount offered to all BUYERs for payment by cash or check as permitted
        by law. The prices for Products are set forth in Ma Labs’s quotation
        which may be increased, decreased or otherwise changed at any time upon
        prior notice to BUYER. Prices quoted are exclusive of and will be
        increased by all applicable federal, state, municipal or other
        government excise, sales, use, occupational or like tax, tariffs,
        customs, duties and import fees, applicable at the time of sales or
        thereafter enacted, all of which BUYER shall be responsible for paying.
        Ma Labs has the legal obligation to collect tax or taxes added by Ma
        Labs to the sales price, which will be paid by BUYER, unless BUYER
        delivers to Ma Labs with the purchase order a proper tax exception
        certificate applicable to Ma Labs and the applicable taxing authority.
        Ma Labs, at its discretion, reserves the right to accept or reject any
        such claims. Unless otherwise noted in the quotation, published or
        quoted prices are net F.O.B. Ma Labs’s facilities located in San Jose,
        California.
      </p>
      <br />
      <p class="lead">BUYER’S Financial Condition</p>
      This Agreement and all shipments made hereunder shall at all times be
      subject to the approval by Ma Labs of BUYER’S financial condition. If the
      financial condition of BUYER at any time becomes unsatisfactory to Ma
      Labs, in Ma Labs’s sole discretion, or if BUYER fails to make any payment
      when due, in addition to any other rights Ma Labs may have, Ma Labs may
      defer or decline to make any shipment or shipments hereunder or may
      condition any such shipment upon receipt of satisfactory security or cash
      payments in advance.
      <p></p>
      <br />
      <p class="lead">Payment</p>
      <p>
        Except as otherwise specified, all payments are due and payable in U.S.
        dollars within approved term by Ma Labs Credit Department. Ma Labs
        reserves the right to require alternative payment terms, including,
        without limitation, a letter of credit or payment in advance. Payments
        not made by the due date may incur, at the sole discretion of Ma Labs, a
        late payment service charge of the lesser of one and one-half percent
        (1-1/2%) per month or the maximum rate permitted by law, computed from
        the date payment was due. All payments, including deposits, shall be
        non-refundable.
      </p>
      <br />
      <p class="lead">Warranty</p>
      <p>
        Ma Labs warrants to the BUYER that supplied standard Products sold will
        meet or exceed advertised Product specifications as published at the
        time of order acceptance and be free from defects in Product and
        workmanship for the specified Product warranty period from date of
        original shipment. Ma Labs will repair or replace any defective Product
        with the exception of any items identified as “not refundable” or
        “non-refundable” on the product detail page. The BUYER must contact the
        manufacturer for repair or replacement for products identified as “not
        refundable” or “non-refundable” on the product detail page. The warranty
        period of the repaired or replaced Product shall be for the remainder of
        the original Products warranty or thirty (30) days, whichever is
        greater. The warranty does not cover any Products which have been
        opened, misused, modified (without the prior written consent of Ma
        Labs), have been subjected to data mining, or otherwise subjected to
        unusual stress or usage, have been improperly maintained or on which any
        original serial numbers or other identification marks have been removed
        or destroyed, all of which are subject to the determination of Ma Labs
        in its discretion. In any event, Ma Labs’s liability shall be limited to
        the replacement value of any damaged or defective part.
      </p>
      <br />
      <p class="lead">Warranty Repair</p>
      <p>
        A Return Product Authorization (RMA) number must be obtained from Ma
        Labs’s Customer Service Department prior to the return of any Product
        for warranty repair. The following information is required to obtain an
        RMA number: Ma Labs Item Number, serial number, Qty, Invoice Date,
        Invoice Number, Problem Description, shipping information, shipping
        instruction, including carrier information. The BUYER will be solely
        responsible for loss or misplacement of Product returned without an RMA
        number. Insufficient packaging may result in loss of warranty. All
        warranty will be void if inspection finds that the Product has been used
        for data mining, or in any way otherwise abused, misused, or altered
        without authorization. Product received more than 15 days after the RMA
        was assigned are subject to refusal at the discretion of the RMA
        Department.
      </p>
      <br />
      <p class="lead">Return Product Authorization (RMA)</p>
      <p>
        A Return Product Authorization (RMA) number must be obtained from Ma
        Labs’s Customer Service Dept. prior to the return of any Product. RMA is
        acceptable within 30 days from the invoice date. The following
        information is required to obtain an RMA number: Ma Labs Item Number,
        serial number, Oty, Invoice Date, Invoice Number, Problem Description.
        The BUYER will be solely responsible for loss or misplacement of Product
        returned without an RMA number. Product received more than 15 days after
        the RMA number is assigned is subject to refusal at the discretion of
        the RMA Department.
      </p>
      <br />
      <p class="lead">Cancelation</p>
      <p>
        Neither this Agreement nor any release hereunder is subject to
        cancelation by BUYER except upon (a) written request of BUYER and (b)
        written approval of Ma Labs. Because Ma Labs’s expenses related to
        canceling firm orders are dependent upon (i) Ma Labs’s inventory
        carrying costs, (ii) the likelihood of Ma Labs quickly selling the
        subject Products to other BUYERS, (iii) Ma Labs’s other related
        out-of-pocket costs, and (iv) administrative costs, Ma Labs may charge
        BUYER a cancelation fee.
      </p>
      <br />
      <p class="lead">Cancelation of Standard Product</p>
      <p>
        If Ma Labs determines the Product being cancelled to be Standard
        Product, Ma Labs may charge a cancelation charge according to the (a)
        quantity being canceled, (b) time frame between BUYER’S request to Ma
        Labs to cancel and the order’s scheduled ship date, and (c) dollar
        amount of order being cancelled. The calculation of the exact
        cancellation charge will be at Ma Labs’s discretion. Any orders that
        constitute twenty-five percent (25%) of the previous six (6) month usage
        of a particular Product and Option will be deemed “custom” and will
        follow the cancellation condition of Custom Product, set forth below.
      </p>
      <br />
      <p class="lead">Cancelation of Custom Product</p>
      <p>
        If Ma Labs determines the Product being canceled to be Custom Product,
        as defined above, or if any Product developed by Ma Labs under special
        contract or requiring conformal coating, extended temperature
        components, or any type of customization as requested by the BUYER, Ma
        Labs may deny BUYER’S cancellation request. If Ma Labs permits the
        cancellation of Custom Product, BUYER agrees to pay Ma Labs for all of
        Ma Labs’s out-of-pocket costs associated with the cancellation of the
        order including, but not limited to: (i) raw Products, (ii) work in
        process, (iii) inventory carrying costs, (iv) scrapping and disposal
        fees, and (v) a reasonable and equitable profit for Ma Labs, which shall
        not be less than twenty percent (20%) of such costs. In no case will the
        cancellation charge be less than Ma Labs’s actual costs (including
        overhead and other indirect costs).
      </p>
      <br />
      <p>
        The amount of cancellation charge to be charged to BUYER shall be
        determined at the sole discretion of Ma Labs and may equal 100% of the
        amount of the order at the time of Ma Labs’s receipt of BUYER’S request
        for cancellation. BUYER is entitled to receive a written notice from Ma
        Labs setting forth how the cancellation charge was calculated.
      </p>
      <br />
      <p>
        Upon payment of the cancellation charge, BUYER shall be entitled to
        receive all raw Products and work in process, and Ma Labs agrees to ship
        such goods to BUYER at BUYER’S expense. Ma Labs reserves the right, by
        written notice of default, to cancel any order, without liability to
        BUYER, in the event of the happening of any of the following: insolvency
        of BUYER, the filing of a voluntary petition in bankruptcy by BUYER, the
        filing of an involuntary petition to have BUYER declared bankrupt, the
        appointment of a receiver or trustee for BUYER, the execution by BUYER
        of an assignment for the benefit of creditors, the discontinuance of
        business by BUYER, or the sale by BUYER of the bulk of its assets other
        than in the usual course of business.
      </p>
      <br />
      <p class="lead">Limitation of Liability</p>
      <p>
        IN NO EVENT SHALL MA LABS BE LIABLE FOR ANY COSTS OR PROCUREMENT OF
        SUBSTITUTE PRODUCT(S) OR MATERIALS, OR FOR ANY GENERAL, CONSEQUENTIAL,
        INCIDENTAL, INDIRECT, EXEMPLARY, PUNITIVE, SPECIAL OR OTHER DAMAGES
        WHATSOEVER, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOSS OF GOOD
        WILL, AND LOSS OF USE OF DATA, RESULTING FROM MA LABS’S PERFORMANCE OR
        FAILURE TO PERFORM UNDER THIS AGREEMENT OR THE FURNISHING, PERFORMANCE
        OR USE OF ANY PRODUCTS, GOODS OR SERVICES SOLD PURSUANT HERETO, WHETHER
        DUE TO BREACH OF CONTRACT, BREACH OF WARRANTY, STRICT LIABILITY, PRODUCT
        LIABILITY, THE NEGLIGENCE OF MA LABS OR ANY OTHER THEORY OF LAW OR
        EQUITY, EVEN IF ON NOTICE OR ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        IN NO EVENT SHALL MA LABS’S LIABILITY EXCEED THE U.S. DOLLAR AMOUNT
        EQUAL TO THE PURCHASE PRICE PAID BY BUYER FOR SUCH PRODUCTS, GOODS OR
        SERVICES GIVING RISE TO SUCH LIABILITY. THE DAMAGE LIMITATIONS PROVIDED
        IN THESE TERMS &amp; CONDITIONS OF SALE SHALL BE EXCLUSIVE AND SHALL BE
        BUYER’S SOLE REMEDY. THE ABOVE DISCLAIMERS AND EXCLUSIONS INCLUDE ANY
        LIABILITY THAT MAY ARISE OUT OF THIRD-PARTY CLAIMS AGAINST BUYER. THIS
        ALLOCATION OF RISK IS REFLECTED IN THE PRICE OF THE PRODUCTS. THIS
        LIMITATION SHALL SURVIVE FAILURE OF ANY ESSENTIAL PURPOSE. BUYER ASSUMES
        ALL LIABILITY FOR ANY AND ALL DAMAGES ARISING FROM OR IN CONNECTION
        WITH, THE USE OR MISUSE OF THE PRODUCTS BY BUYER, ITS EMPLOYEES, OR
        OTHERS. MA LABS’S LIABILITY SHALL TERMINATE UPON THE EXPIRATION OF THE
        WARRANTY PERIOD OF SAID PRODUCT.
      </p>
      <br />
      <p class="lead">Intellectual Property</p>
      <p>
        Ma Labs shall solely own and have exclusive worldwide right, title and
        interest in and to all United States and foreign patents, trademarks,
        service marks, copyrights, mask works, trade secrets, software and all
        other intellectual and industrial property rights in any way related to
        the Products and all modiﬁcations, improvements and derivative works
        related thereto ("Product Intellectual Property Rights" or "Product
        IPR"). Title to all such Product IPR shall at all names remain with Ma
        Labs.
      </p>
      <br />
      <p class="lead">Proprietary Rights</p>
      <p>
        Ma Labs shall have no liability of any kind with respect to any actual
        or alleged infringement of any United States or foreign patent,
        trademark, copyright, deskwork right, trade secret or other intellectual
        property or proprietary right. BUYER agrees to look solely to the
        manufacturer or licensor of the Products with respect to any claim of
        infringement. Furthermore, BUYER agrees to protect, defend, indemnify,
        and hold harmless Ma Labs from all sums, costs, expenses, and attorney’s
        fees, which Ma Labs may incur or be obligated to pay as a result of any
        and all claims, demands, causes or action, or judgments arising out of
        or relating to any use, modification, or enhancement of the Products
        purchased by the BUYER unless such use, modification, or enhancement is
        appr oved in writing by the manufacturer or licenser of the Products.
      </p>
      <br />
      <p class="lead">Force Majeure</p>
      <p>
        The parties agree that Ma Labs is not responsible or liable for any
        delay or failure in performance arising as a result of ﬁre, accident,
        acts of God, acts of public enemy, war, labor disputes, failure or
        delays, transportation, inability to secure Product, raw Products or
        machinery for the manufacturing process, requirements or acts of any
        government or agency thereof, judicial action or other causes beyond Ma
        Labs's reasonable control. In such event, Ma Labs may defer performance
        for a period equal to the time lost by reason of the delay.
      </p>
      <br />
      <p class="lead">Governing Law</p>
      <p>
        This Agreement shall be construed, governed and enforced in accordance
        with the laws of the State of California, including the provisions of
        the California Uniform Commercial Code but excluding its conﬂicts of
        laws provisions. The parties expressly exclude the application of the
        United Nations Convention on Contracts for the International Sale of
        Goods, if applicable.
      </p>
      <br />
      <p class="lead">Forum Selection</p>
      <p>
        The parties agree that any legal action between them arising out of or
        in connection with this Agreement or any BUYER Purchase Order shall be
        commenced and maintained only in the state or federal courts located in
        Santa Clara County, State of California, and that such courts shall have
        exclusive jurisdiction of and shall provide the exclusive venue for any
        such action. BUYER HEREBY UNCONDITIONALLY AGREES THAT IT IS PERSONALLY
        SUBJECT TO AND HEREBY CONSENTS TO THE JURISDICTION OF SUCH COURTS AND
        AGREES THAT IT WILL NOT CONTEST THE JURISDICTION, VENUE OR CONVENIENCE
        OF SUCH COURTS IN ANY ACTION COMMENCED BY MA LABS RELATING TO THIS
        AGREEMENT OR ANY PURCHASE ORDER.
      </p>
      <br />
      <p class="lead">Typographical Or System Errors</p>
      <p>
        In the event a Ma Labs product is listed at an incorrect price due to
        typographical error or systems error, Ma Labs shall have the right to
        refuse or cancel any orders placed for product listed at the incorrect
        price. Ma Labs shall have the right to refuse or cancel any such orders
        whether or not the order has been confirmed and your credit card
        charged. If your credit card has already been charged for the purchase
        and your order is canceled, Ma Labs shall issue a credit to your credit
        card account in the amount of the original charge.
      </p>
      <br />
      <p class="lead">Service and Support</p>
      <p>
        Ma Labs does not offer any technical assistance or support for any
        products or services with the exception of build - to-order PC systems
        assembled by Ma Labs. Technical assistance and supports requests for all
        other products and services must be submitted to the product
        manufacturer or service provider.
      </p>
      <br />
      <p class="lead">Customer Information</p>
      <p>
        Ma Labs is not liable for any damages that may result from incorrect
        order information including customer name, address, phone number, credit
        card number, shipping method selection, or other payment information
        entered into the system by the customer.
      </p>
      <br />
      <p class="lead">Restocking Fee</p>
      <p>
        All returns for refund are subject to a 15% restocking fee unless waived
        by Ma Labs.
      </p>
      <br />
      <p class="lead">Lost or Stolen Packages</p>
      <p>
        BUYER must notify Ma Labs about lost or stolen packages within 14 days
        of invoice date in order to begin the claims process with the shipping
        carrier.
      </p>
      <br />
      <p class="lead">Delivery</p>
      <p>
        Orders will be delivered to the address specified by the BUYER. Ma Labs
        does not control the recipient at the designated address. Ma Labs is not
        responsible for orders that are stolen after they are delivered. Proof
        of dispatch of merchandise will bind the BUYER. For itself and any
        customer or end-user to whom goods are drop-shipped at BUYER’s
        instruction, BUYER assumes the risk of any loss, damage, or theft
        occurring after delivery of the goods to the specified address. Any
        customer or end-user of BUYER shall seek indemnification for such risk
        of loss, damage, or theft occurring after delivery of the goods strictly
        from BUYER or BUYER’s insurer.
      </p>
      <br />
    </div>
  </div>
</template>

<script>
import CompanySubmenu from "../../components/nav/CompanySubmenu.vue";
import BreadcrumbPage from "../../components/BreadcrumbPage.vue";
import { getAPI } from "../../utils/axios-api";
import { ref, onMounted } from "vue";

export default {
  name: "TermsAndConditions",
  components: { CompanySubmenu, BreadcrumbPage },
  setup() {
    const item = ref({});

    //breadcrumb
    const breadcrumb_links = ref([])
    breadcrumb_links.value.push({text: "Company", link: ""})
    breadcrumb_links.value.push({text: "Terms and Conditions", link: "TermsAndConditions"})

    onMounted(() => {
      getAPI.get("/content/pages/62").then((response) => {
        item.value = response.data.body[0].value;
      });
    });

    return { item, breadcrumb_links };
  },
};
</script>

<style scoped>
.submenu-content {
  float: left;
  width: calc(100% - 300px);
  padding: 0 30px 30px;
}
p.normal {
  margin-bottom: 30px;
}
</style>
